<div id="top"></div>
<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">
        <ol class="arrows-pay _mt-90 _ml-60">
           <li><a href="#">Home</a></li>
           <li><a href="#">Booking</a></li>
           <li><a href="#">Passenger Details</a></li>
           <li><a href="#">Review and Pay</a></li>
        </ol>

        <div class="bus-booking-page searchFont _pt-15">
            <div>
                <div class="page-content container row _ml-80">
                    <div class="col-md-7">

                      <div class="row">
                        <div class="bus-booking-summ _p-10">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header ">Passenger Details </h4>
                                </div>
                              </div>
                            </div>
                            <div class="theme-search-results-sidebar-section-checkbox-list-items">
                                <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item"  >
                                  
                                  <div  class="_ml-20 ">
                                    <div class="card">
                                      <div class="card-body">
                                        <div class="fare-dtl ">
                                          <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                              <div class="accordion-body " style="display: block;">
                                                  <div *ngFor="let p of bookingdata.passengers;let m =index" class="fare-dtl">
                                                    <div class="fare-dtl-row total">
                                                        <div class=" left-wing ">
                                                          <span class="fare-total">{{m+1}}. {{p.full_name}}</span> ({{p.phone_number}})
                                                       </div>
                                                        <div class="right-wing">
                                                            <div class="c-price-display u-text-ellipsis " title=""><span
                                                                    class="icon"><i class="ixi-icon-inr icon"
                                                                        aria-hidden="true"></i></span>
                                                                        <span class="">Seat {{ p.seat.id}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <!-- <div class="col-md-12">
                            <div class="theme-payment-page-sections-item ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Passenger Details</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="fare-dtl ">
                                  <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                      <div class="accordion-body " style="display: block;">
                                          <div *ngFor="let p of bookingdata.passengers" class="fare-dtl">
                                            <div class="fare-dtl-row total">
                                                <div class=" left-wing ">
                                                  <span class="fare-total">{{p.full_name}}</span> ({{p.phone_number}})
                                               </div>
                                                <div class="right-wing">
                                                    <div class="c-price-display u-text-ellipsis " title=""><span
                                                            class="icon"><i class="ixi-icon-inr icon"
                                                                aria-hidden="true"></i></span>
                                                                <span class="">Seat {{ p.seat.id}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="row">
                        <div class="bus-booking-summ _p-10">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Payment Details</h4>
                                </div>
                              </div>
                            </div>
                            <div class="theme-search-results-sidebar-section-checkbox-list-items">
                                <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item"  >
                                  
                                  <div id="collapseMpesa" [ngbCollapse]="isCollapsed" class="_ml-20 _mt-10 _mb-10">
                                    <div class="card">
                                      <div class="card-body">
                                        <p class="_mb-20"> 
                                            <span class="left-wing">
                                            <svg class="SGRqwpoui" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
                                            <g>
                                            <path d="M52.179,40.5l-5.596,8.04l-3.949-3.241c-0.426-0.352-1.057-0.287-1.407,0.138c-0.351,0.427-0.289,1.058,0.139,1.407
                                            l4.786,3.929C46.331,50.921,46.556,51,46.786,51c0.045,0,0.091-0.003,0.137-0.01c0.276-0.038,0.524-0.19,0.684-0.419l6.214-8.929
                                            c0.315-0.453,0.204-1.076-0.25-1.392C53.117,39.933,52.495,40.046,52.179,40.5z"/>
                                            <path d="M54.164,35.163C54.709,32.978,55,30.742,55,28.5C55,13.337,42.664,1,27.5,1S0,13.337,0,28.5
                                            c0,8.01,3.444,15.229,8.927,20.259l-0.026,0.023l0.891,0.751c0.056,0.047,0.117,0.086,0.173,0.133
                                            c0.477,0.396,0.972,0.772,1.476,1.136c0.159,0.115,0.318,0.23,0.479,0.341c0.535,0.369,1.085,0.719,1.646,1.051
                                            c0.122,0.071,0.244,0.141,0.366,0.211c0.613,0.349,1.239,0.678,1.881,0.981c0.047,0.022,0.094,0.042,0.141,0.064
                                            c2.089,0.971,4.319,1.684,6.65,2.105c0.062,0.011,0.123,0.022,0.185,0.033c0.723,0.125,1.455,0.225,2.197,0.292
                                            c0.09,0.008,0.181,0.013,0.271,0.021C25.998,55.961,26.744,56,27.5,56c3.262,0,6.454-0.577,9.503-1.702
                                            C39.389,57.168,42.984,59,47,59c7.168,0,13-5.832,13-13C60,41.478,57.677,37.492,54.164,35.163z M2,28.5C2,14.439,13.439,3,27.5,3
                                            S53,14.439,53,28.5c0,1.903-0.214,3.804-0.639,5.666c-0.017-0.008-0.036-0.013-0.053-0.021c-0.376-0.169-0.762-0.32-1.156-0.453
                                            c-0.034-0.011-0.067-0.026-0.101-0.037c-0.411-0.135-0.83-0.251-1.258-0.345c-0.02-0.005-0.04-0.011-0.06-0.016
                                            c-0.417-0.09-0.841-0.158-1.271-0.207c-0.03-0.004-0.06-0.01-0.09-0.014C47.921,33.027,47.464,33,47,33
                                            c-5.923,0-10.923,3.986-12.485,9.413C34.077,42,33.818,41.425,33.818,40.8v-2.957c0.198-0.243,0.405-0.518,0.617-0.817
                                            c1.096-1.547,1.975-3.269,2.616-5.123c1.266-0.602,2.085-1.864,2.085-3.289v-3.545c0-0.866-0.318-1.708-0.886-2.369v-4.667
                                            c0.052-0.52,0.236-3.448-1.883-5.864C34.524,10.065,31.541,9,27.5,9s-7.024,1.065-8.867,3.168
                                            c-2.119,2.416-1.935,5.346-1.883,5.864v4.667c-0.568,0.661-0.886,1.503-0.886,2.369v3.545c0,1.101,0.494,2.128,1.339,2.821
                                            c0.81,3.173,2.476,5.575,3.092,6.389v2.894c0,0.816-0.445,1.566-1.162,1.958l-7.907,4.313c-0.253,0.138-0.502,0.298-0.752,0.477
                                            C5.276,42.792,2,36.022,2,28.5z M25.605,53.922c-0.109-0.008-0.218-0.015-0.326-0.025c-0.634-0.056-1.266-0.131-1.893-0.234
                                            c-0.026-0.004-0.052-0.01-0.077-0.014c-1.327-0.222-2.632-0.548-3.903-0.974c-0.034-0.011-0.068-0.023-0.102-0.035
                                            c-1.237-0.42-2.44-0.939-3.601-1.544c-0.067-0.035-0.135-0.068-0.201-0.103c-0.515-0.275-1.019-0.573-1.515-0.883
                                            c-0.143-0.09-0.284-0.181-0.425-0.273c-0.456-0.298-0.905-0.608-1.343-0.936c-0.045-0.034-0.088-0.07-0.133-0.104
                                            c0.032-0.018,0.064-0.036,0.097-0.054l7.907-4.313c1.359-0.742,2.204-2.165,2.204-3.714v-3.603l-0.233-0.278
                                            c-0.021-0.025-2.176-2.634-2.999-6.215l-0.091-0.396l-0.341-0.221c-0.481-0.311-0.769-0.831-0.769-1.392v-3.545
                                            c0-0.465,0.198-0.898,0.557-1.223l0.33-0.298v-5.57l-0.009-0.131c-0.003-0.024-0.298-2.429,1.396-4.36
                                            C21.583,11.837,24.06,11,27.5,11c3.425,0,5.897,0.83,7.346,2.466c1.692,1.911,1.415,4.361,1.413,4.381l-0.009,5.701l0.33,0.298
                                            c0.359,0.324,0.557,0.758,0.557,1.223v3.545c0,0.724-0.475,1.356-1.181,1.574l-0.498,0.154l-0.16,0.496
                                            c-0.589,1.833-1.429,3.525-2.496,5.032c-0.259,0.367-0.514,0.695-0.736,0.948l-0.248,0.283V40.8c0,1.587,0.868,3.015,2.268,3.746
                                            C34.033,45.024,34,45.508,34,46c0,0.292,0.01,0.583,0.029,0.873c0.007,0.103,0.021,0.205,0.031,0.307
                                            c0.009,0.096,0.018,0.191,0.029,0.287c0.01,0.09,0.015,0.181,0.027,0.27c0.023,0.17,0.056,0.338,0.086,0.507
                                            c0.02,0.115,0.035,0.231,0.058,0.345c0,0,0.002,0.008,0.003,0.012c0.006,0.03,0.015,0.058,0.021,0.088
                                            c0.031,0.146,0.068,0.291,0.104,0.436c0.025,0.101,0.045,0.202,0.072,0.302c0.024,0.088,0.055,0.173,0.081,0.26
                                            c0.034,0.116,0.07,0.231,0.108,0.345c0.024,0.072,0.042,0.145,0.067,0.216c0.07,0.201,0.15,0.399,0.23,0.596
                                            c0.001,0.003,0.002,0.005,0.003,0.008c0.061,0.15,0.125,0.297,0.191,0.444c0.049,0.109,0.1,0.218,0.152,0.326
                                            c0.034,0.071,0.064,0.143,0.099,0.213c0.023,0.046,0.05,0.09,0.074,0.136c0.084,0.163,0.173,0.322,0.264,0.48
                                            c0.027,0.047,0.051,0.096,0.078,0.143C33.119,53.527,30.33,54,27.5,54C26.866,54,26.234,53.969,25.605,53.922z M47,57
                                            c-3.661,0-6.901-1.805-8.902-4.564c-0.083-0.114-0.158-0.231-0.236-0.347c-0.054-0.08-0.111-0.158-0.162-0.239
                                            c-0.043-0.069-0.085-0.138-0.127-0.208c-0.045-0.074-0.085-0.15-0.128-0.225c-0.069-0.122-0.143-0.241-0.207-0.365
                                            c-0.047-0.091-0.089-0.185-0.134-0.278c-0.012-0.025-0.023-0.051-0.035-0.076c-0.075-0.157-0.153-0.312-0.221-0.472
                                            c-0.036-0.085-0.063-0.173-0.097-0.258c-0.077-0.199-0.155-0.398-0.221-0.602c-0.031-0.095-0.055-0.193-0.083-0.289
                                            c-0.009-0.03-0.017-0.059-0.025-0.088c-0.056-0.199-0.11-0.399-0.155-0.603c-0.014-0.063-0.025-0.127-0.038-0.191
                                            c-0.012-0.06-0.025-0.121-0.036-0.181c-0.041-0.222-0.075-0.446-0.103-0.672c-0.003-0.026-0.006-0.052-0.009-0.079
                                            c-0.009-0.082-0.022-0.164-0.029-0.246C36.021,46.681,36,46.343,36,46c0-0.315,0.021-0.626,0.047-0.934
                                            c0.028-0.295,0.067-0.599,0.122-0.919l0.074-0.433C37.298,38.742,41.719,35,47,35c0.446,0,0.89,0.034,1.332,0.089
                                            c0.101,0.012,0.199,0.031,0.299,0.046c0.365,0.055,0.728,0.127,1.086,0.219c0.075,0.019,0.15,0.037,0.225,0.058
                                            c0.882,0.247,1.735,0.601,2.537,1.063C55.773,38.377,58,41.93,58,46C58,52.065,53.065,57,47,57z"/>
                                            </g>
                                            </svg>
                                              {{this.bookingdata.payee.name}}</span><br>
                                            <span>
                                              <svg class=" SGRqwpoui" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                                <path d="M17.965 13.806a2.083 2.083 0 00-2.947 0l-1.321 1.32A39.095 39.095 0 018.872 10.3l1.322-1.322a2.085 2.085 0 000-2.948l-1.77-1.768a2.13 2.13 0 00-2.945 0l-.97.97a2.926 2.926 0 00-.367 3.67 39.133 39.133 0 0010.95 10.96 2.956 2.956 0 003.667-.37l.97-.97a2.085 2.085 0 000-2.948l-1.764-1.768z"></path>
                                             </svg>
                                               {{this.bookingdata.payee.phone_number}}</span>
                                          </p>
                                        <h5>Payment Method</h5>
                                        <p class="checkbox-title"> Pay with <img src="assets/img/brands/mpesa.jpg" alt="M-PESA"> </p>

                                         An stk push will be sent on your mobile number
                                         <br>
                                         Before you proceed, please confirm you have enough money in your M-Pesa.
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item" >
                                  <div class="_mb-10">
                                    <label  class="switch ">
                                      <input (click)="isCollapsedVoomaClicked()" type="radio" name="switch-choice" >
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="checkbox-title _ml-10"><img src="assets/img/brands/vooma1.jpg" alt=""> KCB VOOMA </span>
                                  </div>
                                  <div id="collapseVooma" [ngbCollapse]="isCollapsedVooma" class="_ml-20 _mt-10 _mb-20">
                                    <div class="card">
                                      <div class="card-body">
                                         An stk push will be sent on your mobile number
                                         <br>
                                          Before you proceed, please confirm you have enough money in your KCB account.
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                                <!-- <div class="checkbox theme-search-results-sidebar-section-checkbox-list-item" >
                                  <div class="_mb-10">
                                    <label  class="switch ">
                                      <input (click)="isCollapsedCardClicked()" type="radio" name="switch-choice" >
                                      <span class="slider round"></span>
                                    </label>
                                    <span class="checkbox-title _ml-10"><img src="assets/img/brands/visa.jpg" alt="">Mastercard or Visa card </span>
                                  </div>
                                    <div id="collapseCard" [ngbCollapse]="isCollapsedCard" class="_ml-20 _mt-10 _mb-20">
                                      <div class="card">
                                        <div class="card-body">
                                           Before you proceed, please confirm you have enough money in your  card.
                                        </div>
                                      </div>
                                    </div>
                                </div> -->



                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="bus-booking-summ">
                          <div class="col-md-12">
                            <div class="theme-payment-page-sections-item-pay ">
                              <div class="theme-payment-page-signin">
                                <div class="theme-payment-page-signin-body">
                                  <h4 class="theme-payment-page-signin-title review-header _mb-10">Use a discount </h4>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 _mb-10">
                                    <div class="theme-search-area-section first theme-search-area-section-line">
                                      <label class="theme-search-area-section-label _mb-10">Enter your voucher code.</label>
                                      <div class="theme-search-area-section-inner">
                                        <input class="theme-search-area-section-input typeahead"
                                         name="voucherCode"
                                         type="text"
                                         id="voucherCode">
                                      </div>
                                    </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="col-md-4 _ml-20" style="transform: translateY(0px);">
                      <div class="row">
                      
                      <div class="bus-booking-summ _p-5">
                        <div class="col-md-12">
                          <h4 class="theme-payment-page-signin-title review-header "> {{(bookingdetails.result.is_shuttle) ? bookingdetails.search_from : bookingdetails.pickup_location}} -  {{(bookingdetails.result.is_shuttle) ? bookingdetails.search_to : bookingdetails.dropoff_location}}</h4>
                          <p>{{bookingdetails.search_details.traveldate}}</p>
                          <p><strong>Bus operator:</strong> {{bookingdetails.result.operator.alias | titlecase }}</p>
                          <div class="bus-info-cntr col-md-6 u-ib u-v-align-top">
                            <span class="u-ib u-v-align-middle bus-agent-name">
                                <span class="u-ib u-v-align-middle bus-ac-row _pb-10">
                                    Depature 
                                </span>
                            </span>
                            <div class="info pickup font-trip">
                                <div class="">
                                  <span>
                                    <span>{{bookingdetails.result.departure_time | timeFormater}}</span>
                                    <p>{{bookingdetails.search_details.traveldate}} </p>
                                  </span></div>
                            </div>
                          </div>
                          <div class="bus-info-cntr col-md-6 u-ib u-v-align-top">
                            <span class="u-ib u-v-align-middle bus-agent-name">
                                <span class="u-ib u-v-align-middle bus-ac-row _pb-10">
                                  Dropoff 
                                </span>
                            </span>
                            <div class="info pickup font-trip">
                                <div class="">
                                  <span>
                                    <span>{{bookingdetails.result.arrival_time | timeFormater}}</span>
                                    <p>{{bookingdetails.result.arrival_date}} </p>
                                  </span></div>
                            </div>
                          </div>
                          
                        </div>
                          
                          
                      </div>
                      </div>
                      <div class="row">
                        <div class="fare-dtl-acc _p-5">
                            <div class="c-accordion-item expanded collapsible diff-collapsed ">
                                <div class="accordion-hdr  collapsible">
                                    <div class="accordion-hdr-cntnt">
                                         <div class="fare-hdr">
                                            <strong *ngIf="is_return">Return Ticket</strong>
                                            <strong *ngIf="!is_return">One-Way Ticket</strong>
                                            </div>
                                    </div><span class="ixi-icon-chevron"></span>
                                </div>
                                <div class="accordion-body " style="display: block;">
                                    <div class="fare-dtl">
                                       <div *ngIf="!bookingdetails.result.is_shuttle" class="fare-dtl-row total">
                                          <div class="left-wing">Selected seat(s) no.</div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title="">
                                                <span class="icon">
                                                  <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                                </span>
                                                <span *ngFor="let seat of bookingdetails.seatsSelected; let i = index">
                                                  {{seat.id}}{{i < bookingdetails.seatsSelected.length - 1 ? ', ' : ''}}
                                                </span>
                                              </div>
                                          </div>
                                      </div>
                                      <div  *ngIf="is_return && !return_trip.result.is_shuttle" class="fare-dtl-row total">
                                          <div class="left-wing">Selected return seat(s) no.</div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title="">
                                                <span class="icon">
                                                  <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                                </span>
                                                <span class="_pd-10 " *ngFor="let seat of return_trip.seatsSelected">{{seat.id}}</span>
                                                <span *ngFor="let seat of return_trip.seatsSelected; let i = index">
                                                  {{seat.id}}{{i < return_trip.seatsSelected.length - 1 ? ', ' : ''}}
                                                </span>
                                              </div>
                                          </div>
                                      </div>
                                      <div *ngIf="policyAmount > 0" class="fare-dtl-row total">
                                        <div class="left-wing">Travel insurance.</div>
                                        <div class="right-wing">
                                            <div class="c-price-display u-text-ellipsis " title="">
                                              <span class="icon">
                                                <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                              </span><span class="_pd-10 " > Ksh {{policyAmount}}</span>
                                            </div>
                                        </div>
                                      </div>
                                      <div *ngIf="policyAmount > 0" class="fare-dtl-row total">
                                        <div class="left-wing">Fare.</div>
                                        <div class="right-wing">
                                            <div class="c-price-display u-text-ellipsis " title="">
                                              <span class="icon">
                                                <i class="ixi-icon-inr icon" aria-hidden="true"></i>
                                              </span><span class="_pd-10 " > Ksh {{bookingdetails.totalfare}}</span>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="fare-dtl-row total">
                                          <div class=" left-wing "><span class="fare-total">Total</span></div>
                                          <div class="right-wing">
                                              <div class="c-price-display u-text-ellipsis " title=""><span
                                                      class="icon"><i class="ixi-icon-inr icon"
                                                          aria-hidden="true"></i></span><span class="">{{farecurrency}} {{paytotalfare}}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <hr class="hr-0">
                                      <div class="fare-dtl-row total">
                                        <div class="theme-search-area-section first theme-search-area-section-line">
                                          <label class="theme-search-area-section-label _mb-10">Apply Promo Code(optional)</label>
                                          <div class="theme-search-area-section-promo">
                                            <form>
                                              <input class="theme-search-area-section-input-promo "
                                              name="voucherCode"
                                              [(ngModel)]="voucherCode"
                                              type="text"
                                              placeholder="Enter promo code if you have one"
                                              id="voucherCode">
                                            </form>
                                           
                                          </div>
                                        </div>
                                    </div>
                                      
                                    </div>
                                </div>
                                <div class="disclaimer">Please Note: Once seats are selected
                                    and paid for, they cannot be changed. Tickets are non-refundable.</div>
                                    <div *ngIf="isError" class="invalid-feedback">
                                      <div class="invalid-select _ml-10"  >{{errorMessage}}</div>
                                      <p><a class="text-blue _ml-10 _pd-10 pull-right" href="/booking"><i  class="fas fa-edit"></i> Modify Booking</a></p>
                                    </div>
                                </div>
                                
                        </div>
                        <button  (click)="submitBooking()"     class="c-btn u-link ">
                          <div class="u-ripple"><span class="u-ripple-circle"></span></div>
                          <img *ngIf="loading" src="assets/img/gifs/76.gif">
                          <span *ngIf="!loading">PAY</span>
                      </button>
                      </div>
                     
                    </div>
                </div>



            </div>
        </div>
    </div>
     <!-- Feedback Button -->
     <div class="btn-group feedback-btn" [class.feedback-btn-hide]="hideFeedbackBtn" role="group" aria-label="Feedback button with hide option">
      <button type="button" id="openModalButton" class="btn btn-primary font-weight-500 p-0 rounded-0 shadow-none" data-toggle="modal" data-target="#feedbackModal" >Feedback</button>
      <button type="button" class="btn btn-primary p-0 rounded-0 shadow-none" aria-label="Hide" (click)="hideFeedback()"><i class="fa fa-times text-2" aria-hidden="true"></i></button>
    </div>
    
    <app-feedback-dialog></app-feedback-dialog>
<app-footer></app-footer>
