import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router:Router,
    private dataService: DataService
  ) { }

  ngOnInit() {
  }


  clickNairobiTl(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Kitale',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Kitale',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)


  }

  clickNairobiMb(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Malaba',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Malaba',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    this.getTopRoutes(formdata)

  }

  clickNairobiBg(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'pickup' : 'Nairobi',
      'dropoff' : 'Bungoma',
      'pickupname' : 'Nairobi',
      'dropoffname' : 'Bungoma',
      'traveldate' :  traveldate,
      'returndate': '',
      'returning' : false
    }

    
    this.getTopRoutes(formdata)
  }

  getTopRoutes(formdata){

    
    const route = `${formdata.pickup}-to-${formdata.dropoff}`;  // e.g., "Nairobi-to-Mombasa"

    const queryParams = {
      fromCityName: formdata.pickup,
      toCityName: formdata.dropoff,
      onward: formdata.traveldate,
    };

    this.router.navigate([`/booking/${route}`], { queryParams });



  }

}
