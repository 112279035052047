import { Component, OnInit } from '@angular/core';
import { ActivatedRoute , Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-check-payment',
  templateUrl: './check-payment.component.html',
  styleUrls: ['./check-payment.component.css']
})
export class CheckPaymentComponent implements OnInit {
  payForm: UntypedFormGroup;
  formDataObj={}
  submitted:boolean = false;
  data: any ={}; 
  api_error:Error;
  isError:boolean = false;
  errorStatus:boolean = false;
  loadingData:boolean = true;
  loadingDataButton:boolean = false;
  errorMessage:string =''
  errorRetryMessage:string =''
  succesMessage:string=''
  idParam:any;
  res:any;
  

  constructor(
    private service: PaymentService,
    private _fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient // Inject the HttpClient
  ) {}


  ngOnInit(): void {
    this.formDataObj['mobileNumber'] = ['', [Validators.required, Validators.minLength(10), Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];

    this.payForm = this._fb.group(this.formDataObj);

    // Fetch the id from the URL and make the API call
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.idParam = id
        this.fetchData(id);
      }else{
        window.location.href = '/index.html';
      }
    });
  }


  get f() { return this.payForm.controls; }



  fetchData(id: string) {
       this.service.fetchData(id).subscribe(
      data  => {
        this.res = data
        this.loadingData=false;

      this.data = this.res.data.data
     
      this.payForm.controls['mobileNumber'].setValue(this.data.phone_number);

    },
    error  => {

      this.loadingData=false;
      this.errorStatus = true
      this.api_error=new Error().deserialize(error.error.errors)
      
      if(this.api_error[0]){
        this.errorMessage = this.api_error[0]
      }else{
        this.errorMessage="Sorry there has been a technical problem.Please try again ";

      }
 
    
      }
    
      );
    // this.http.get(`https://api.buupass.com/fetch/data/${id}`).subscribe(
    //   response => {
    //     this.data = response;
    //     // Handle the response data as needed
    //   },
    //   error => {
    //     console.error('There was an error!', error);
    //   }
    // );
  }


  submitForm(){
    this.loadingDataButton = true
      let value = this.payForm.value;

      this.submitted=true;

      let jdata={
        "m": this.idParam,
        "phone_number": value.mobileNumber
      }


      this.service.submitCheckPay(jdata,this.idParam).subscribe(
        data  => {
          this.res=data;

          this.loadingData = false
     
          if(this.res.status){
            this.succesMessage=this.res.data.message;

            let code = this.data;
            let f=Base64.encode(JSON.stringify(code))
            this.router.navigate(['/process/pay/', f])

          }{
            this.errorRetryMessage=this.res.data.message;
          }


        },
        error  => {
          this.loadingData = false
          this.isError=true;
          if(error.status==500){
            this.errorRetryMessage="Sorry there has been a technical problem.Please try again ";
          }else{
            this.api_error=new Error().getTheMessage(error.error.errors)
            this.errorRetryMessage=this.api_error[0];

          }
    
        }
    
        );

  }
  }
