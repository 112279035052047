<div class="hidden-sm hidden-md hidden-lg ">
    <app-nav></app-nav>
</div>
<div class="visible-lg">
  <app-headerhome ></app-headerhome>
</div>
<div class="progress-cntr-wrpr">
        <div class="progress-cntr">
            <div class="progress-tracker-cntr transparent">
                <div class="progress-tracker">
                    <div class="progress-item first ">
                        <div class="item-success">
                            <div class="progess-item-label">Booking Passenger details</div>
                        </div>
                    </div>
                    <div class="progress-item ">
                        <div class="item-success">
                            <div class="progess-item-label">Confirm Payment</div>
                        </div>
                    </div>
                    <div class="progress-item last current">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Ticket</div>
                        </div>
                    </div>
                    <div class="progress-tracker-completed" style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
<div class="theme-page-section  theme-page-section-gray searchFont">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <i class="fa fa-check-circle fa-3x theme-payment-success-header-icon"></i>
                  <h1 class="theme-payment-success-title">Booking Successful</h1>
                  <p class="theme-payment-success-subtitle">A confirmation SMS has been sent to provided mobile number.Thank You.</p>
                </div>
                <div class="theme-payment-success-box">
                  <ul class="theme-payment-success-summary">
                    <li>Booking Reference
                      <span>{{rawTicketDetails.data.booking_id}}</span>
                    </li>
                    <li>Travel Date
                      <span>{{rawTicketDetails.depature_date}}</span>
                    </li>
                    <li>Pickup
                      <span>{{rawTicketDetails.search_from}}</span>
                    </li>
                    <li>Dropoff
                      <span>{{rawTicketDetails.search_to}}</span>
                    </li>

                    <li class="passenger-details">
                      Passenger(s) <i class="text-light fa fa-male"></i>
                      <br>
                            <div  class="passenger-info" *ngFor="let passenger of rawTicketDetails.passengers">

                            <p><b>{{passenger.full_name}}</b>
                              <span *ngIf="!rawTicketDetails.is_shuttle"><small class="p-left"> Seat: </small>
                              <b>{{passenger.seat.id}}</b></span>
                            </p>
                              </div>
                      </li>
                  </ul>
                  <p class="pull-right"><a href="/">Go Back Home</a></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

       <!-- Feedback Button -->
      <div class="btn-group feedback-btn" [class.feedback-btn-hide]="hideFeedbackBtn" role="group" aria-label="Feedback button with hide option">
        <button type="button" id="openModalButton" class="btn btn-primary font-weight-500 p-0 rounded-0 shadow-none" data-toggle="modal" data-target="#feedbackModal" >Feedback</button>
        <button type="button" class="btn btn-primary p-0 rounded-0 shadow-none" aria-label="Hide" (click)="hideFeedback()"><i class="fa fa-times text-2" aria-hidden="true"></i></button>
      </div>
      
      <app-feedback-dialog></app-feedback-dialog>
<app-footer></app-footer>
