<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
<div id="container _mt-40 bg-buupass ">

        <ol class=" container arrows _mt-90 ">
           <li><a href="#">Home</a></li>
           <li><a href="#">Booking</a></li>
           <li><a href="#">Passenger Details</a></li>
           <li><a href="#">Reveiw and Pay</a></li>
        </ol>
        
        <div class="bus-booking-page searchFont _pt-10">
          <div class="page-content container">
            <div class="col-md-8 ">
              <h2 *ngIf="!errorStatus && !loadingData" class="Heading_Route R_Header-font _mtb-10 ">
                <small>From:</small> {{data.from}} <small class="_pl-20">To:</small> {{data.to}}
              </h2>
              <section *ngIf="loadingData" class="traveller-details">
                <div class="CheckTicket_Details_Traveller_Details CheckTicket_bjIJShDED">
                  <div class="CheckTicket_ROUTE_DETAILS CheckTicket_fJqiXaASES">
                    <div class="row">
                      <div class="col-md-2 col-md-offset-4">
                        <img class="text-center loading-container" src="assets/img/icons/spinning-circles.svg">
                      </div>
                    </div>
                  </div>
                </div>
             </section>
              <section *ngIf="!errorStatus && !loadingData" class="route-details">
                <div class="CheckTicket_Details_Traveller_Details CheckTicket_bjIJShDED">
                <div class="CheckTicket_ROUTE_DETAILS CheckTicket_fJqiXaASES">
                   <div class="CheckTicket_Details_Page CheckTicketjzOoeZS">
                      <div class="CheckTicket_Details_Page CheckTicketjzOoeZAD">
                         <div class="CheckTicket_Details_Page CheckTicketjzOoeZASE">
                            <h2 class="CheckTicket_Review_Traveller_Details CheckTicketfQeOFuAqe">
                             Trip Summary
                            </h2>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="CheckTicket_ROUTE_DETAILS CheckTicketdRTGS">
                   <div class="CheckTicketSTVSH">
                      <div class="CheckTicketCard__CheckTicketWrapper-sc-nv2axp-0 CheckTicket_fJqiXa_DESR">
                         <div class="CheckTicket _reservation _collapsed">
                            <div class="CheckTicket__JourneyActionButtonsWrapper-sc-eb4y4s-0 jGonIh">
                               <div class="JourneyActionButtons">
                                  
                               </div>
                            </div>
                            <div class="CheckTicketJourney _oneWay">
                               <div class="CheckTicketTrip">
             
                                  <div class="CheckTicketTimeline">
                                     <div class="">
                                        <div class="CheckTicketPart__CheckTicketPartWrapper-sc-hxyeqa-0">
                                           <div class="CheckTicketDate">
                                              <div class="CheckTicket_Details_Page eFmSfH">
                                                 <div>
                                                    <svg class="Icon_CheckTicket_Review_Traveller_Details-62738 CheckTicketqwpoui CheckTicketDate-icon" viewBox="0 0 24 24" preserveAspectRatio="CheckTickettyMP meet">
                                                       <path d="M19.917 4.5c.916 0 1.666.75 1.666 1.667v14.166c0 .917-.75 1.667-1.666 1.667H4.083c-.916 0-1.666-.75-1.666-1.667V6.167c0-.917.75-1.667 1.666-1.667h1.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667A.657.657 0 007 6.833v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h5.25c.25 0 .417.167.334.417v1.916c0 .417.333.667.666.667a.657.657 0 00.667-.667v-4c0-.5.333-.833.833-.833s.834.333.834.833v1.5a.18.18 0 00.166.167h2.334zM19.5 20.333c.25 0 .417-.166.417-.416v-10c0-.25-.167-.417-.417-.417h-15c-.25 0-.417.167-.417.417v10c0 .25.167.416.417.416h15zM8.25 10.75c.5 0 .833.333.833.833s-.333.834-.833.834h-.833c-.5 0-.834-.334-.834-.834s.334-.833.834-.833h.833z"></path>
                                                    </svg>
                                                    <span class="CheckTicketDate-date "> <b class="CheckTicket-classes">Travel on: </b><time class="" datetime="{{data.travel_date}}">{{data.travel_date}}</time></span>
                                                 </div>
                                                 <div class="BadgePrimitive__StyledBadge-q8r1qt-0 dLFuQ">
                                                    <div class="BadgePrimitive__StyledBadgeContent-q8r1qt-2 bSjvH">
                                                      <b class="CheckTicket-classes">Bus: </b>{{data.operator}} </div>
                                                 </div>
                                              </div>
                                           </div>
                                           <div class="CheckTicketPart _flight">
                                              <div class="CheckTicketPart-milestone">
                                               
                                               <svg class="Icon_CheckTicket_Review_Traveller_Details-62738 CheckTicketqwpoui CheckTicketPart-milestoneIcon _train" fill="#000000" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>bus</title>
                                                <path d="M0 22.281v-13.563c0-0.438 0.25-1 0.594-1.344 0.094-0.094 0.219-0.156 0.313-0.219h0.031c1.5-1.156 3.469-2 5.719-2.469 1.188-0.219 2.438-0.344 3.75-0.344s2.563 0.125 3.75 0.344c2.25 0.469 4.219 1.313 5.719 2.469h0.031c0.094 0.063 0.188 0.125 0.281 0.219 0.344 0.344 0.625 0.906 0.625 1.344v13.563c0 1-0.688 1.781-1.594 2v1.813c0 0.844-0.688 1.563-1.531 1.563-0.875 0-1.563-0.719-1.563-1.563v-1.75h-11.438v1.75c0 0.844-0.719 1.563-1.563 1.563-0.875 0-1.563-0.719-1.563-1.563v-1.813c-0.906-0.219-1.563-1-1.563-2zM15.625 6.688h-10.438c-0.563 0-1.031 0.469-1.031 1.031 0 0.531 0.469 1 1.031 1h10.438c0.563 0 1-0.469 1-1 0-0.563-0.438-1.031-1-1.031zM3.125 17.063h14.531c0.563 0 1.031-0.5 1.031-1.063v-5.156c0-0.563-0.469-1.063-1.031-1.063h-14.531c-0.563 0-1 0.5-1 1.063v5.156c0 0.563 0.438 1.063 1 1.063zM4.25 22.281c0.906 0 1.625-0.75 1.625-1.656 0-0.938-0.719-1.656-1.625-1.656-0.938 0-1.656 0.719-1.656 1.656 0 0.906 0.719 1.656 1.656 1.656zM16.531 22.281c0.938 0 1.688-0.75 1.688-1.656 0-0.938-0.75-1.656-1.688-1.656-0.906 0-1.625 0.719-1.625 1.656 0 0.906 0.719 1.656 1.625 1.656z"></path>
                                                </svg>
                                              </div>
                                              <div class="CheckTicketPartOverview">
                                                 <div class="CheckTicketPartOverview-airports">
                                                    <div class="CheckTicketPartOverview-outbound">
                                                       <span class="CheckTicketPartOverviewField _time">
                                                          <div class="CheckTicketPartAirport__HiddenFlightWrapper-sc-txv0fy-1"><b class="CheckTicket-depatures">Depatue: </b></div>
                                                       </span>
                                                       <div class="CheckTicketPartOverview-airports-fields">
                                                          <div class="CheckTicketPartOverview-airports-fields-merged"><span class="CheckTicketPartOverviewField _name">{{data.pick_up}}</span></div>
                                                       </div>
                                                    </div>
                                                    <div class="CheckTicketPartOverview-inbound">
                                                       <span class="CheckTicketPartOverviewField _time">
                                                          <div class="CheckTicketPartAirport__HiddenFlightWrapper-sc-txv0fy-1"><b class="CheckTicket-depatures">Arrival: </b></div>
                                                       </span>
                                                       <div class="CheckTicketPartOverview-airports-fields">
                                                          <div class="CheckTicketPartOverview-airports-fields-merged"><span class="CheckTicketPartOverviewField _name">{{data.drop_off}}</span></div>
                                                       </div>
                                                    </div>
                                                 </div>
                                                 <div>
                                                    <div class="CheckTicketPartDetail">
                                                     
                                                    </div>
                                                 </div>
                                              </div>
                                              <div class="CheckTicketPart-icon-folding-wrapper">
                                                 <svg class="Icon_CheckTicket_Review_Traveller_Details-62738 CheckTicketqwpoui CheckTicketPart-icon-folding" viewBox="0 0 24 24" preserveAspectRatio="CheckTickettyMP meet">
                                                    <path d="M12.71 18.703a.904.904 0 01-1.342.002l-2.796-3.302a.898.898 0 01.066-1.271.904.904 0 011.274.065l1.826 2.23a.4.4 0 00.594-.001l1.753-2.18a.904.904 0 011.275-.07c.37.332.402.9.07 1.27l-2.72 3.257zM9.912 9.805a.904.904 0 01-1.274.065.898.898 0 01-.066-1.27l2.796-3.303a.904.904 0 011.342.003l2.72 3.255c.332.37.3.94-.07 1.271a.904.904 0 01-1.275-.07l-1.753-2.18a.4.4 0 00-.594 0L9.912 9.804z"></path>
                                                 </svg>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             
             </div>
             </section>
             <section *ngIf="!errorStatus  && !loadingData" class="traveller-details">
              <div class="CheckTicket_Details_Traveller_Details CheckTicket_bjIJShDED">
                <div class="CheckTicket_ROUTE_DETAILS CheckTicket_fJqiXaASES">
                  <div class="CheckTicket_Details_Page CheckTicketjzOoeZS">
                      <div class="CheckTicket_Details_Page CheckTicketjzOoeZAD">
                        <div class="CheckTicket_Details_Page CheckTicketjzOoeZASE">
                            <h2 class="CheckTicket_Review_Traveller_Details CheckTicketfQeOFuAqe">
                              Passenger details
                            </h2>
                        </div>
                      </div>
                  </div>
                  <div class=" mt-16">
                      <div class=" CheckTicket_fJqiXa_TRS">
                        <ol class="list-number _pl-20-m" type="1">
                                      <li>
                          <div class="CheckTicket_Details_Page CheckTicketfQeOFuRTsa">
                              <div class="CheckTicket_Review_Traveller_Details-23142 CheckTicketgbTpPuAWEr">
                                <div class="CheckTicket_Details_Page CheckTicket__lbuWWmSTHA">
                                    <div>
                                      <div class="CheckTicket_Details_Page jINduD">
                                          <span class="CheckTicket__TextStyle-12376 CheckTicket_bjIJShTHE">{{data.name}}</span>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </li>
                                    </ol>
                      
                                
                      </div>
                      <hr>
                      <div class="CheckTicket_Details_Page CheckTicketcsVMRT">
                        <div class="CheckTicket_Details_Page CheckTicketjzOoeZS">
                          <div class="CheckTicket_Details_Page CheckTicketjzOoeZAD">
                              <div class="CheckTicket_Details_Page CheckTicketjzOoeZASE">
                                <h2 class="CheckTicket_Review_Traveller_Details CheckTicketfQeOFuAqe">
                                  Payment details
                                </h2>
                              </div>
                          </div>
                        </div>
                        <div class="CheckTicket_Review_Traveller_Details-56823 CheckTicketcsVMqhMT">
                          <div class="row">
                           <div class="col-md-8">
                             <h3><small><b>Total</b>: Ksh</small> {{data.amount}}</h3>
                           </div>
                          </div>
                          <div class="row">
                           <div class="col-md-8">
                             <p class="text-center">{{succesMessage}}</p>
                           </div>
                          <div class="col-md-8">
                                <p class="text-center">{{errorRetryMessage}}</p>
                           </div>
                   
                          </div>
                         
                          
                        
                          <div class="row">
                           <p>Payment Mobile Number</p>
                            <div class="col-md-6 ">
                              <form [formGroup]="payForm">
                              <div class="theme-payment-page-form-item form-group">
                                <input class="form-control" type="text" placeholder="Mpesa number"
                                name="mobileNumber"
                                id="mobileNumber"
                                formControlName="mobileNumber"
                                >
                                <small class="invalid-select" *ngIf="payForm.get('mobileNumber').touched && payForm.get('mobileNumber').hasError('minlength')">Your phone number should not be less or greater than 10. </small>
                                <small class="invalid-select" *ngIf="payForm.get('mobileNumber').touched && payForm.get('mobileNumber').hasError('required')">This field is required!</small>
                                <small class="invalid-select" *ngIf="payForm.get('mobileNumber').touched && payForm.get('mobileNumber').hasError('pattern')">Your phone number should be valid!</small>
                              </div>
                              </form>
                            </div>
                          
                              <div class="col-md-6 _mt-mb-40">
                                      <button  (click)="submitForm()"  type="submit"  [ngClass]="( payForm.valid) ? 'enabled':'disabled'"  [disabled]="payForm.invalid"  class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow ">
                                          <img *ngIf="loadingDataButton" src="assets/img/gifs/76.gif">
                                          <b *ngIf="!loadingDataButton" >PAY NOW</b>
                                      </button>
                              </div>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
           </section>
           <section *ngIf="errorStatus && !loadingData" class="traveller-details _mt-40">
            <div class="CheckTicket_Details_Traveller_Details CheckTicket_bjIJShDED">
              <div class="CheckTicket_ROUTE_DETAILS CheckTicket_fJqiXaASES">
                <div class="row">
                  <div class="col-md-8">
                    <p class="text-center text-red">{{errorMessage}}</p>
                  </div>
                  <div class="col-md-4">
                   <a href="/booking"> <button   type="button"    class="text-center theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow ">
                      Book Again
                  </button></a>
                  </div>
                </div>
               
              </div>
            </div>
         </section>
            </div>
            </div>
        </div>
    </div>
<app-footer></app-footer>

