<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
    <div id="container ">
        <div class="bus-booking-page searchFont">
                <div class="page-content row">
                    <div class="col-md-12">
                        <div class="_mt-120 bus-booking-summ ">
                        <div class="row">
                            <div  class=" bus-info-cntr col-md-6 u-ib u-v-align-top">
                                <span
                                    class="u-ib u-v-align-middle bus-agent-name">
                                    <span class="u-ib u-v-align-middle bus-ac-row">
                                        <div class="ixi-icon-ac u-v-align-middle u-ib icon"></div>
                                    </span></span>
                                <div class="bus-info u-ib u-v-align-middle">
                                    <div class="left-wing u-ib">{{confirmData.search_from}}</div>
                                    <div class="timeline-widget u-ib">
                                    <div  class="f-29">  →   </div>
                                    </div>
                                    <div class="left-wing u-ib">{{confirmData.search_to}}</div>
                                </div>
                                <div *ngIf="confirmData.payment_type =='Vooma' " class="pickup-info-cntr _mt-20 info seats">
                                    <div class="left-wing u-ib info-heading">VOOMA Mobile Application :</div>
                                    <div  class="info-value">1. Launch Vooma application</div>
                                    <div  class="info-value">2. Select merchant name on the menu</div>
                                    <div  class="info-value">3. Enter  <b>{{ confirmData.data.payment_payload.data.order_reference}}</b></div>
                                    <div  class="info-value">4. Follow the Promt.</div>
                                    <br>
                                    <div class="left-wing u-ib info-heading">VOOMA USSD  :</div>
                                   <div  class="info-value">1. Dial <b>*844#</b></div>
                                           <div  class="info-value">2. Select pay </div>
                                           <div  class="info-value">3. Select paybill </div>
                                           <div  class="info-value">4.Select enter paybill no . </div>
                                           <div  class="info-value">
                                                5. Enter business no. <b>537226</b>
                                            </div>
                                            <div  class="info-value">6. Enter <b> <b>{{ confirmData.data.payment_payload.data.order_reference}}</b> </b> as reference</div>
                                           <div  class="info-value">7. Enter  <b><i>Ksh {{confirmData.data.payment_payload.data.amount}} </i></b>as the amount</div>
                                           <div  class="info-value">8. Enter Vooma PIN</div>
                                    <!-- <div class="info-value"><img src="assets/img/greenline/popup.png"/></div> -->
                                    <!-- <div class="info-value"><img src="assets/img/greenline/vooma.jpg"/></div> -->
                                </div>
                                <div *ngIf="confirmData.payment_type =='Mpesa'" class="pickup-info-cntr _mt-20 info seats">
                                   <div class="left-wing u-ib info-heading">Mpesa Instructions to Pay :</div>
                                   <div  *ngIf="confirmData.payment_type =='Mpesa'" class="info-value">
                                     <img width="100" src="assets/img/greenline/lipa-na-mpesa.png"/>
                                   </div>
                                   <div  class="info-value">1. Check on a payment popup on your phone. </div>
                                   <div  class="info-value">2. Input your MPESA PIN and click OK. </div>
                                   <div  class="info-value">3. Wait for upto 2-minutes as we try validate your transaction. </div>

                                   <!-- <div class="info-value"><img src="assets/img/greenline/popup.png"/></div> -->
                               </div>

                            </div>
                            <div class="u-ib col-md-6 _mt-20  u-v-align-top pickup-info-cntr">
                                <div class="info pickup">
                                    <div class="info-heading">Pickup Point</div>
                                    <div class="info-value text-grey"><span>{{confirmData.search_from}}
                                        &nbsp;&nbsp;&nbsp;<span>{{confirmData.departure_time | timeFormater}}</span><span
                                                class="dot"></span><span>{{confirmData.depature_date}} </span></span></div>
                                </div>

                                <div class="info dropoff">
                                    <div class="info-heading">Dropoff Point</div>
                                    <div class="info-value text-grey"><span
                                            class="u-ib">{{confirmData.search_to}}<span
                                                class="dot"></span><span>{{confirmData.arrival_time | timeFormater}}</span></span></div>
                                </div>
                                <div class="info seats">
                                    <div class="info-heading">Passenger(s)</div>
                                    <div  *ngFor="let passenger of confirmData.passengers" class="_mt-5 info-value">
                                        <i class="text-light fa fa-male"></i>  {{passenger.full_name}}  <span *ngIf="!confirmData.is_shuttle">,{{ passenger.seat.id}} </span>
                                    </div>
                                </div>
                                <div  class="info operator-booking u-link">
                                    <img class="mobile-images" alt="{{confirmData.operator.name}}" src="assets/img/images/{{confirmData.operator.alias}}.webp" >
                                </div>
                                <div class="loader-img u-ib _mt-30">
                                  <svg *ngIf="isWaiting" xmlns:svg="http://www.w3.org/2000/svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="10px" viewBox="0 0 457 60" xml:space="preserve">
                                      <g>
                                          <circle fill="#007929" cx="-31" cy="30" r="30"/>
                                          <circle fill="#329353" cx="-97" cy="30" r="24"/>
                                          <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                                          <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                                          <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                                          <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                      </g>
                                      <g>
                                          <circle fill="#007929" cx="488" cy="30" r="30"/>
                                          <circle fill="#329353" cx="554" cy="30" r="24"/>
                                          <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                                          <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                                          <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                                          <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                      </g>
                                  </svg>
                                  <br>
                                    <span *ngIf="isWaiting" class="destinationHeading text-red">Processing your payments please wait.....</span>
                                    <span *ngIf="!isWaiting" class="destinationHeading text-danger">{{ errorMessage }}</span>
                                </div>
                                <div  *ngIf="confirmData.payment_type =='Vooma'" class="info-value"><img src="assets/img/greenline/join-mobile.png"/></div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div id='yehtu_3801d1664c2b43c7854b47c7bf20276e_zone_125501_sect_59126_site_52307' data-sender='yehtu'></div>

                    </div>
                </div>
        </div>
    </div>
<app-footer></app-footer>
<script>
  gtag('event', 'conversion', {
    'send_to': 'AW-601441993/vsCKCIHm29sBEMmN5Z4C'
  });
</script>
    <!-- <div id="checkout-ebuupass" class="buupass-sandbox">

            <div id="checkout-ebuupass"
                class="buupass-context-popup buupass-overlay checkout-background-color-black buupass-logo-color-white">
                <a href="#" class="buupass-close" aria-label="close" role="button"></a>
                <div class="buupass-modal">
                    <div class="buupass-logo">
                            <div class="provider-info">

                                    <h4>Processing your payments please wait ....</h4>
                                    <div class="ixigo-logo u-ib "><img
                                            src="assets/img/greenline/mpesa-logo.png"></div>
                                    <div class="loader-img u-ib">
                                            <svg xmlns:svg="http://www.w3.org/2000/svg"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="152px" height="20px" viewBox="0 0 457 60" xml:space="preserve">
                                            <g>
                                                <circle fill="#007929" cx="-31" cy="30" r="30"/>
                                                <circle fill="#329353" cx="-97" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="-163" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="-229.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="-295" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="61 0;127 0;193 0;259 0;325 0;391 0;457 0;523 0;589 0;655 0;721 0;787 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                            <g>
                                                <circle fill="#007929" cx="488" cy="30" r="30"/>
                                                <circle fill="#329353" cx="554" cy="30" r="24"/>
                                                <circle fill="#64ae7d" cx="620" cy="30" r="19"/>
                                                <circle fill="#c8e2d1" cx="686.5" cy="30.5" r="13.5"/>
                                                <circle fill="#e1efe6" cx="753" cy="31" r="11"/>
                                                <animateTransform attributeName="transform" type="translate" values="0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;0 0;-61 0;-127 0;-193 0;-259 0;-325 0;-391 0;-457 0;-523 0;-589 0;-655 0;-721 0;-787 0;" calcMode="discrete" dur="1280ms" repeatCount="indefinite"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="provider-logo u-ib"><img class="u-v-align-middle "
                                            src="assets/img/logo.png"></div>
                                </div>

                    </div>
                    <div *ngIf="isError" class="buupass-message seat-book-error">{{errorMessage}}.&nbsp; </div>
                    <div *ngIf="isWaiting" class="buupass-message seat-book-waiting">{{waitingMessage}}.&nbsp; </div>
                    <div class="buupass-continue">
                            <button (click)="requestConfirm()" class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary">CONFIRM</button>
                    </div>
                    <div class="buupass-loader">
                        <div class="buupass-spinner"></div>
                    </div>
                </div>
                <div class="buupass-iframe-container">
                    <div class="zoid-outlet"></div>
                </div>

            </div>
        </div> -->
