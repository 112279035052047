<div class="modal fade" id="feedbackModal" tabindex="-1" role="dialog" aria-labelledby="feedbackModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="feedbackModalLabel">Feedback</h4>
        </div>
        <div class="modal-body">
          <!-- Insert your Zoho form HTML here -->
          <div id='zohoSupportWebToCase' align='center'>
            <form
              name='zsWebToCase_904493000040204007'
              id='zsWebToCase_904493000040204007'
              action='https://desk.zoho.com/support/WebToCase'
              target="submissionFrame" 
              method='POST'
              onSubmit='return handleFormSubmit(event);'
              enctype='multipart/form-data'
            >
              <!-- Hidden and configuration fields -->
              <input type='hidden' name='xnQsjsdp' value='edbsnc7d49410ccc3c5f39ba1ce9d90118b0c' />
              <input type='hidden' name='xmIwtLD' value='edbsnb3d9146e8a5a4e8867a34d4fdcb65cf82a8b6aa5cb98c25718be518baf4f39d9' />
              <input type='hidden' name='xJdfEaS' value='' />
              <input type='hidden' name='actionType' value='Q2FzZXM=' />
              <input type="hidden" id="property(module)" value="Cases" />
              <input type="hidden" id="dependent_field_values_Cases" value="&#x7b;&quot;JSON_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_SELECT_VALUES&quot;&#x3a;&#x7b;&#x7d;,&quot;JSON_MAP_DEP_LABELS&quot;&#x3a;&#x5b;&#x5d;&#x7d;" />
              <input type='hidden' name='returnURL' value='https&#x3a;&#x2f;&#x2f;buupass.com' />
  
              <!-- Form fields (customizable based on your needs) -->
              <table border='0' cellspacing='0' class='zsFormClass'>
                <tr>
                  <td colspan='2' class='zsFontClass'>
                    <strong>Feedback</strong>
                  </td>
                </tr>
                <br>
                <tr>
                  <td nowrap class='zsFontClass ' width='25%' align='left'>Last Name&nbsp;&nbsp;</td>
                  <td align='left' width='75%'>
                    <input type='text' maxlength='120' name='Contact Name' class='manfieldbdr' />
                  </td>
                </tr>
                <tr>
                  <td nowrap class='zsFontClass ' width='25%' align='left'>Email&nbsp;&nbsp;</td>
                  <td align='left' width='75%'>
                    <input type='text' maxlength='120' name='Email' value='' class='manfieldbdr' />
                  </td>
                </tr>
                <tr>
                  <td nowrap class='zsFontClass ' width='25%' align='left'>Experience Rating &nbsp;&nbsp;</td>
                  <td align='left' width='75%'>
                    <select name='Experience Rating' value='' onchange="setDependent(this, false)" id='CASECF4'>
                      <option value=''>-None-</option>
                      <option value='Very satisfied'>Very satisfied</option>
                      <option value='Somewhat satisfied'>Somewhat satisfied</option>
                      <option value='Neutral'>Neutral</option>
                      <option value='Dissatisfied'>Dissatisfied</option>
                      <option value='Very dissatisfied'>Very dissatisfied</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td nowrap class='zsFontClass ' width='25%' align='left'>Support Issue &nbsp;&nbsp;</td>
                  <td align='left' width='75%'>
                    <select name='Support Issue' value='' onchange="setDependent(this, false)" id='CASECF1'>
                      <option value=''>-None-</option>
                      <option value='App Issue'>App Issue</option>
                      <option value='Assist to Book'>Assist to Book</option>
                      <option value='Availability of Buses'>Availability of Buses</option>
                      <option value='Bus Fare'>Bus Fare</option>
                      <option value='Bus Refunds'>Bus Refunds</option>
                      <option value='Bus Route'>Bus Route</option>
                      <option value='Flight Booking'>Flight Booking</option>
                      <option value='Flight Fares'>Flight Fares</option>
                      <option value='Flight Refunds'>Flight Refunds</option>
                      <option value='Flight Reschedule'>Flight Reschedule</option>
                      <option value='Flight Ticket'>Flight Ticket</option>
                      <option value='How to Book'>How to Book</option>
                      <option value='MiniApp Issue'>MiniApp Issue</option>
                      <option value='Mpesa Time Out'>Mpesa Time Out</option>
                      <option value='Office Location'>Office Location</option>
                      <option value='Other'>Other</option>
                      <option value='Other Bus Companies'>Other Bus Companies</option>
                      <option value='Parcel Inquiry'>Parcel Inquiry</option>
                      <option value='Pickup Point'>Pickup Point</option>
                      <option value='Running Late'>Running Late</option>
                      <option value='SGR Booking'>SGR Booking</option>
                      <option value='System Downtime'>System Downtime</option>
                      <option value='Ticket Confirmation'>Ticket Confirmation</option>
                      <option value='Ticket Rebooking'>Ticket Rebooking</option>
                      <option value='Ticket Reschedule'>Ticket Reschedule</option>
                      <option value='USSD Issue'>USSD Issue</option>
                      <option value='Web Issue'>Web Issue</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td nowrap class='zsFontClass ' width='25%' align='left'>Subject&nbsp;&nbsp;</td>
                  <td align='left' width='75%'>
                    <textarea type='text' maxlength='255' name='Subject' value='' class='manfieldbdr' ></textarea>
                  </td>
                </tr>
                <tr>
                  <td style='padding: 11px 5px 0px 5px;' colspan='2' align='center' width='25%'>
                    <input type='submit' form="zsWebToCase_904493000040204007"  id="zsSubmitButton_904493000040204007" class='zsFontClass' value='Submit'> &nbsp; &nbsp; 
                    <input type='button' class='zsFontClass' value='Reset' onclick="zsResetWebForm('904493000040204007')">
                  </td>
                </tr>
                <tr>
                  <td width='25%' align='left'></td>
                  <td style='padding: 0px 5px;' align='left' width='75%'>
                    <div class='wb_FtCon wb_common'>
                      <span>powered by </span>
                      <a target='_blank' rel='noopener noreferrer' href='https://zoho.com/desk' class='wb_logoCon'>
                        <img class='wb_logo' src='https://d1ydxa2xvtn0b5.cloudfront.net/app/images/portalLogo.de847024ebc0131731a3.png' />
                      </a>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  