import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService, MixpanelService } from '../services/index'
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  sub:any;
  ticketDetails:any;
  rawTicketDetails:any;
  hideFeedbackBtn=false;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit() {
    this.dataService.successValueChange.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('success-buupass')
      }else{
        localStorage.setItem('success-buupass', message)
      }

      let booking=JSON.parse(Base64.decode(message));
      this.ticketDetails=booking.data.data;
      this.rawTicketDetails = booking;


      moengage.track_event('Booking Complete', {
        'BookingId':booking.data.booking_id,
        'PassengerName': booking.fullname,
        'PassengerEmail': booking.email,
        'PassengerPhone': '254' + booking.phoneno.slice(-9),
        'PassengerResidence': booking.search_from,
        'Busoperator': booking.operator.alias,
        'TotalFare':parseInt(booking.total_fare),
        'TotalPassengers':booking.passengers.length,
        'DepatureDate':booking.depature_date,
        'DepatureTime':booking.departure_time
      });

      moengage.destroy_session();


      let mixTicketSold:any = {
        route:booking.from + " - " + booking.to,
        travelDate: booking.depature_date,
        bookingChannel: "Web",
        amount: booking.data.payment_payload.data.amount,
        ticketCount: booking.passengers.length,
        source: 'buupassSite',
        role: 'customer',
    }
    this.mixpanelService.track('TicketSold', mixTicketSold);


      if(!message){
        message = localStorage.getItem('success-buupass')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('success-buupass', message)
      }

       if(!message) {
         this.router.navigate(['/'])
       }
    });

    this.mixpanelService.track('PageView', {
      pageName: 'Success Page',
      source: 'buupassSite',
      role: 'customer',
    });

  }


  hideFeedback(){
    this.hideFeedbackBtn=true;
  }

}
