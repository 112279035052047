import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.loadZohoScripts();
    this.loadCustomScripts();
  }

  loadZohoScripts() {
    const zohoScript = this.renderer.createElement('script');
    zohoScript.src = 'https://d17nz991552y2g.cloudfront.net/app/js/jqueryandencoder.36e24db6ecfe6de0dce2.js';
    this.renderer.appendChild(document.body, zohoScript);
  }

  loadCustomScripts() {
    const customScript = this.renderer.createElement('script');
    customScript.type = 'text/javascript';
    customScript.text = `
      window.trimBoth = function(str) {
        return jQuery.trim(str);
      };

      window.getMapDependenySelectValues = function(module, key) {
        var dependencyObj = jQuery.parseJSON(jQuery("[id='dependent_field_values_" + module + "']").val());
        return dependencyObj ? dependencyObj[key] : undefined;
      };

      window.setDependent = function(obj, isload) {
        var name = obj.id || (obj[0] && obj[0].id) || "";
        var module = jQuery("[id='property(module)']").val();
        var val = "";
        var myObject = window.getMapDependenySelectValues(module, "JSON_VALUES");
        if (myObject) {
          val = myObject[name];
        }
        var mySelObject = window.getMapDependenySelectValues(module, "JSON_SELECT_VALUES");
        if (val && val !== "null" && mySelObject) {
          var fields = val;
          for (var i in fields) {
            if (fields.hasOwnProperty(i)) {
              var isDependent = false;
              var label = i.replace(/\\)/g, '_____').replace(/\\(/g, '____').replace(/\\./g, '___');
              var depObj = document.forms['zsWebToCase_904493000040204007'][label];
              if (depObj && depObj.options) {
                var mapValues = "";
                var selected_val = depObj.value;
                var depLen = depObj.options.length - 1;
                for (var n = depLen; n >= 0; n--) {
                  if (depObj.options[n].selected) {
                    mapValues = mapValues ? mapValues + ";;;" + depObj.options[n].value : depObj.options[n].value;
                  }
                }
                depObj.value = "";
                var selectValues = mySelObject[label];
                for (var k in values) {
                  var rat = k === "-None-" ? "" : k;
                  if (rat === window.trimBoth(obj.value)) {
                    isDependent = true;
                    depObj.length = 0;
                    values[k].forEach(function(displayValue) {
                      var optionElement = document.createElement("OPTION");
                      optionElement.value = displayValue === "-None-" ? "" : displayValue;
                      optionElement.text = displayValue;
                      optionElement.selected = mapValues && mapValues.includes(displayValue);
                      depObj.options.add(optionElement);
                    });
                  }
                }
                if (!isDependent) {
                  depObj.length = 0;
                  selectValues.forEach(function(actualValue) {
                    var optionElement = document.createElement("OPTION");
                    optionElement.value = actualValue === "-None-" ? "" : actualValue;
                    optionElement.text = actualValue;
                    depObj.options.add(optionElement);
                  });
                  depObj.value = selected_val;
                }
                if (!isload) {
                  window.setDependent(depObj, false);
                }
              }
            }
          }
        }
      };

      window.setAllDependancyFieldsMapping = function() {
        var mapDependancyLabels = window.getMapDependenySelectValues(jQuery("[id='property(module)']").val(), "JSON_MAP_DEP_LABELS");
        if (mapDependancyLabels) {
          mapDependancyLabels.forEach(function(label) {
            var obj = document.forms['zsWebToCase_904493000040204007'][label];
            if (obj) {
              window.setDependent(obj, true);
            }
          });
        }
      };

      var zsWebFormMandatoryFields = new Array("Contact Name", "Email", "Subject");
      var zsFieldsDisplayLabelArray = new Array("Last Name", "Email", "Subject");

      window.zsValidateMandatoryFields = function() {
        var name = '';
        var email = '';
        var isError = 0;
        for (var index = 0; index < window.zsWebFormMandatoryFields.length; index++) {
          isError = 0;
          var fieldObject = document.forms['zsWebToCase_904493000040204007'][window.zsWebFormMandatoryFields[index]];
         
          if (fieldObject) {
            if (!fieldObject.value.trim()) {
              alert(window.zsFieldsDisplayLabelArray[index] + ' cannot be empty ');
              fieldObject.focus();
              isError = 1;
              return false;
            }
            if (fieldObject.name === 'Email' && !fieldObject.value.match(/^([\\w_][\\w\\-_.+\\'&]*)@(?=.{4,256}$)(([\\w]+)([\\-_]*[\\w])*[\\.])+[a-zA-Z]{2,22}$/)) {
              alert('Enter a valid email-Id');
              fieldObject.focus();
              return false;
            }
            if (fieldObject.nodeName === 'SELECT' && fieldObject.options[fieldObject.selectedIndex].value === '-None-') {
              alert(window.zsFieldsDisplayLabelArray[index] + ' cannot be none');
              fieldObject.focus();
              return false;
            }
            if (fieldObject.type === 'checkbox' && !fieldObject.checked) {
              alert('Please accept ' + window.zsFieldsDisplayLabelArray[index]);
              fieldObject.focus();
              return false;
            }
          }
        }
          
        if (isError === 0) {
          document.getElementById('zsSubmitButton_904493000040204007').setAttribute('disabled', 'disabled');
          return true;
        }
      };

    window.handleFormSubmit= function(event) {
    // Prevent the default form submission behavior
    event.preventDefault();

    // Perform client-side validation before submission
    if (!zsValidateMandatoryFields()) {
        return false;
    }

    // Prepare the form data for submission
    var form = document.getElementById('zsWebToCase_904493000040204007');
     form.submit();

    // Prevent further action
    return false;
}

        window.setSelectAll = function (id) {
        var parentElement = document.getElementById(id);
        var hiddenInput = parentElement.querySelector('#hiddenoptions');
        var selectAllElement = parentElement.querySelector('#selectall' + id);
        var selectedValues = [];
        var checkboxes = parentElement.querySelectorAll('.wb_multi_pick_input');
        checkboxes.forEach(function(cb) {
          cb.checked = selectAllElement.checked;
          if (cb.checked && cb.value) {
            selectedValues.push(cb.value);
          }
        });
        hiddenInput.value = selectedValues.join(',');
      }

       window.setMultiSelectOption = function(id, obj) {
        var parentElement = document.getElementById(id);
        var hiddenInput = parentElement.querySelector('#hiddenoptions');
        var selectAllElement = parentElement.querySelector('#selectall' + id);
        var selectedStr = hiddenInput.value;
        var selectedValues = selectedStr ? selectedStr.split(',') : [];
        if (obj.checked && obj.value) {
          selectedValues.push(obj.value);
        } else if (!obj.checked && obj.value) {
          selectedValues.splice(selectedValues.indexOf(obj.value), 1);
          selectAllElement.checked = false;
        } else {
          selectAllElement.checked = false;
        }
        hiddenInput.value = selectedValues.join(',');
      }

      window.zctt = function() {
        var tt, mw = 400,
          top = 10,
          left = 0,
          doctt = document;
        var ieb = doctt.all ? true : false;
        return {
          showtt: function(cont, wid) {
            if (tt == null) {
              tt = doctt.createElement('div');
              tt.setAttribute('id', 'tooltip-zc');
              doctt.body.appendChild(tt);
              doctt.onmousemove = this.setpos;
              doctt.onclick = this.hidett;
            }
            tt.style.display = 'block';
            tt.innerHTML = cont;
            tt.style.width = wid ? wid + 'px' : 'auto';
            if (!wid && ieb) {
              tt.style.width = tt.offsetWidth;
            }
            if (tt.offsetWidth > mw) {
              tt.style.width = mw + 'px'
            }
            h = parseInt(tt.offsetHeight) + top;
            w = parseInt(tt.offsetWidth) + left;
          },
          hidett: function() {
            tt.style.display = 'none';
          },
          setpos: function(e) {
            var u = ieb ? event.clientY + doctt.body.scrollTop : e.pageY;
            var l = ieb ? event.clientX + doctt.body.scrollLeft : e.pageX;
            var cw = doctt.body.clientWidth;
            var ch = doctt.body.clientHeight;
            if (l < 0) {
              tt.style.left = left + 'px';
              tt.style.right = '';
            } else if ((l + w + left) > cw) {
              tt.style.left = '';
              tt.style.right = ((cw - l) + left) + 'px';
            } else {
              tt.style.right = '';
              tt.style.left = (l + left) + 'px';
            }
            if (u < 0) {
              tt.style.top = top + 'px';
              tt.style.bottom = '';
            } else if ((u + h + left) > ch) {
              tt.style.top = '';
              tt.style.bottom = ((ch - u) + top) + 'px';
            } else {
              tt.style.bottom = '';
              tt.style.top = (u + top) + 'px';
            }
          }
        };
      }();

      document.addEventListener('readystatechange', function() {
        if (document.readyState === 'complete' && window.zsRegenerateCaptcha) {
          zsRegenerateCaptcha();
        }
    
          window.setAllDependancyFieldsMapping();
          document.getElementById('zsSubmitButton_904493000040204007').removeAttribute('disabled');
        
      });

       window.zsResetWebForm=function(webFormId) {
        document.forms['zsWebToCase_' + webFormId].reset();
        document.getElementById('zsSubmitButton_904493000040204007').removeAttribute('disabled');
        setAllDependancyFieldsMapping();
      }
    `;
    this.renderer.appendChild(document.body, customScript);
  }
}
