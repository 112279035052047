import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  // init(userToken: string): void {
  //   mixpanel.init('4834cb45cce08ab0b7b84746319d478a', {'loaded':function() {
  //     var distinct_id = mixpanel.get_distinct_id();
  // }});
  //   mixpanel.identify(userToken);
  // }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

}


