import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownStatusService } from 'src/app/services/dropdown-status.service';
import { FromStatusService } from 'src/app/services/from-status.service';
import { ReturnServiceService } from 'src/app/services/return-service.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { formatDate  } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css']
})
export class SearchFormComponent implements OnInit,AfterViewInit {
  pickup='';
  dropoff='';
  reactiveForm: UntypedFormGroup;
  mobiletraveldate='';
  mobilereturndate='';
  returning=false;
  formdata={};
  submitted = false;
  isOpen=false;
  bsInlineValue =formatDate(new Date(),"yyyy-MM-dd","en-KE");
  constructor(
    private router: Router,
    private destinationService: DropdownStatusService,
    private fromService: FromStatusService,
    private returnservice: ReturnServiceService,
    private _fb: UntypedFormBuilder,
    private dataService : DataService,
  ) {
    this.reactiveForm = _fb.group({
      pickup:[null, Validators.required],
      dropoff:[null, Validators.required],
      mobiletraveldate:[this.bsInlineValue, Validators.required],
      mobilereturndate:[this.bsInlineValue]
    });
      this.destinationService.dropoffDateSource.subscribe(dvalue => {
        this.reactiveForm.controls['dropoff'].setValue(dvalue);
        this.dropoff=dvalue;
      });
      this.fromService.valueMobileChange.subscribe(dvalue => {
        this.reactiveForm.controls['pickup'].setValue(dvalue);
        this.pickup=dvalue;
      });
      this.dataService.travelValueMobile.subscribe(tvalue => {
        this.mobiletraveldate=tvalue;
        this.reactiveForm.controls['mobiletraveldate'].setValue(tvalue);
      });
      this.dataService.returndateMobileChange.subscribe(rvalue => {
          this.mobilereturndate=rvalue;
          this.reactiveForm.controls['mobilereturndate'].setValue(rvalue);
      });
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });
   }
    ngOnInit(): void {
    }
    ngAfterViewInit() {
      this.returnservice.currentValue.subscribe(rvalue => {
        this.returning=rvalue;
      });
    }
  destinationsClicked(){
    this.router.navigate(['/mobile-to'])
  }
  depaturesClicked(){
    this.router.navigate(['/mobile-from'])
  }
  depatureDateClicked(){
    this.router.navigate(['/depature-date'])
  }
  returnDateClicked(){
    this.router.navigate(['/return-date'])
  }
  isOneWayClicked(){
    this.returning =false;
    this.returnservice.changeValue(false);
  }
  isReturnTripClicked(){
    this.returning =true;
    this.returnservice.changeValue(true);
  }
    submitForm() {
      if(this.reactiveForm.value.returndate == null){
        this.returning =false;
        this.isOpen=false;
      }
        this.submitted = true;
           // stop here if form is invalid
        if (this.reactiveForm.invalid ) {
            return;
        }
        let value = this.reactiveForm.value;
     

        const route = `${value.pickup}-to-${value.dropoff}`;  // e.g., "Nairobi-to-Mombasa"

        const queryParams = {
          fromCityName: `${value.pickup}`,
          toCityName: `${value.dropoff}`,
          onward: this.mobiletraveldate,
          ...(this.returning && { return: this.mobilereturndate })  // Add 'return' only if returning is true

        };

    
        this.router.navigate([`/booking/${route}`], { queryParams });

      

    }
}
